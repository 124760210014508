import styled, { keyframes, css } from "styled-components";
import { pulse, fadeInDown } from "react-animations";
import Theme from "./Theme.js";

export { default as NavBar } from "./navbar.js";
export { default as Dropdown } from "./dropdown.js";
export { default as Input } from "./input.js";
export { default as Notification } from "./notification.js";
export { default as Drawer } from "./drawer.js";
export { default as Table } from "./table.js";
export { default as Button } from "./button.js";
export { default as Select } from "./select.js";
export { default as Picker } from "./picker.js";
export { default as Modal } from "./modal.js";
export { default as Tabs } from "./tabs.js";
export { default as DatePicker } from "./datepicker.js";
export { default as Checkbox } from "./checkbox.js";
export { default as MultiSelect } from "./multi-select.js";
export { default as MultiSelectList } from "./multi-select-list.js";
export { default as Slideshow } from "./slideshow.js";
export { default as NavMenu } from "./nav-menu.js";

export const Loader = styled.div`
  box-sizing: border-box;
  margin: auto;
  width: ${(props) => props.size || "2vw"};
  height: ${(props) => props.size || "2vw"};
  border: 0.2vw solid ${(props) => props.color || "#fff"};
  border-size: ${(props) => props.thickness};
  border-radius: 50%;
  animation: ${keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }`} 1.2s infinite;
  border-color: ${(props) => props.color || "#fff"} transparent transparent
    transparent;
`;

export const NavButton = styled.button`
  margin: ${(props) => props.margin || "auto 2vw auto 2vw"};
  padding: ${(props) => props.padding || "0"};
  outline: none;
  border: none;
  font-size: ${(props) => (props.mobile ? "1.5vh" : "2vh")};
  font-family: ${Theme.BANNER_FONT};
  font-weight: 800;
  background: none;
  color: ${(props) => props.color || Theme.NAV_TEXT_COLOR};
  width: ${(props) => props.width || "auto"};
  cursor: pointer;
  animation: ${(props) => props.animation};
  animation-iteration-count: 1;
  animation-delay: ${(props) => props.animationDelay};
  animation-fill-mode: both;
  text-align: ${(props) => props.align || "center"};
  position: ${(props) => props.position};
  border-radius: 20px;
  overflow: ${(props) => props.overflow || "visible"};
  &:hover {
    ${(props) =>
      props.disableHover
        ? null
        : css`
            animation: 2.5s ${keyframes`${pulse}`} infinite;
            animation-fill-mode: forwards;
            transition: background-color 1s ease;
            background-color: ${Theme.ACCENT_COLOR};
          `}
  }
`;

export const Clickable = styled.button`
  position: ${(props) => props.position};
  margin: ${(props) => props.margin || "0"};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  padding: 0;
  outline: none;
  border: ${(props) => props.border || "none"};
  border-radius: ${(props) => props.borderRadius};
  background: ${(props) => props.background || "none"};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
  z-index: 2;
  &:hover {
    ${(props) =>
      props.hover
        ? css`
            transition: background-color 1s ease;
            background-color: ${props.hover};
          `
        : null}
  }
`;
export const Text = styled.div`
  position: ${(props) => props.position};
  background-color: ${(props) => props.background};
  width: ${(props) => props.width || "auto"};
  font-size: ${(props) => (props.size ? props.size : "2vh")};
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  font-family: ${(props) => props.fontFamily || Theme.MAIN_FONT};
  text-align: ${(props) => props.textAlign};
  display: ${(props) => (props.hidden ? "none" : "default")};
  margin-left: ${(props) => (props.align === "left" ? "0" : "auto")};
  margin-right: ${(props) => (props.align === "right" ? "0" : "auto")};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  color: ${(props) => (props.color ? props.color : "black")};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
`;

export const Row = styled.div`
  display: ${(props) => props.display || "flex"};
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  flex-wrap: ${(props) => props.noWrap || "wrap"};
  padding: ${(props) => props.padding || "0"};
  justify-content: ${(props) => props.justify};
  zIndex: ${(props) => props.zIndex};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "auto"};
  border: ${(props) => props.border || "none"};
  border-bottom: ${(props) => props.borderBottom || "none"};
  box-shadow: ${(props) => props.boxShadow || "none"};
  background-color: ${(props) => props.backgroundColor || "none"};
  overflow: ${(props) => props.overflow || "visible"};
  overflow-x: ${(props) => props.overflowX || "visible"};
  overflow-y: ${(props) => props.overflowY || "visible"};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
trasform:rotateX
  trasform: rotate;
`;

export const Column = styled.div`
  display: ${(props) => props.display || "flex"};
  flex-direction: column;
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height || "auto"};
  max-height: ${(props) => props.maxHeight};
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "0"};
  border: ${(props) => props.border || "none"};
  box-shadow: ${(props) => props.boxShadow || "none"};
  background-color: ${(props) => props.backgroundColor || "none"};
  justifycontent: ${(props) => props.justifyContent};
  animation: ${(props) => props.duration} ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
`;

export const Section = styled.div`
  display: flex;
  position: ${(props) => props.position || "default"};
  flex-direction: column;
  margin: ${(props) => props.margin || "0"};
  padding: ${(props) => props.padding || "0"};
  height: ${(props) => props.height || "auto"};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};
  width: ${(props) => props.width || "auto"};
  background-color: ${(props) => props.backgroundColor || "none"};
  background-image: url(${(props) => props.backgroundImage || "none"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-bottom: ${(props) => props.border || "none"};
  animation: ${(props) => props.duration} ${(props) => props.animation};
  animation-delay: ${(props) => props.animationDelay};
  text-align: center;
  ${(props) =>
    props.transition === "out"
      ? css`
          transition: height 0.5s;
          height: 0px;
        `
      : css`
          transition: height 0.5s;
          height: props.height;
        `};
`;
export const Hint = styled.div`
  display: flex;
  margin: ${(props) => props.margin || "auto"};
  width: ${(props) => props.width || "auto"};
  font-weight: bold;
  justify-content: center;
  color: ${Theme.WHITE};
  font-size: ${(props) => props.size || "2vw"};
  font-family: ${Theme.MAIN_FONT};
  background-color: black;
  border-radius: 20px;
  box-shadow: 0 0 10px #586387;
  animation: ${(props) => props.animation};
  animation-delay: ${(props) => props.animationDelay};
  animation-fill-mode: both;
`;

export const Image = styled.img`
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "auto"};
  border: ${(props) => props.border};
  background: none;
  position: ${(props) => props.position};
  display: ${(props) => props.display};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  border-radius: ${(props) => props.borderRadius};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
`;

export const DemoFrame = styled.div`
  display: flex;
  height: 60vh;
  border: ${(props) =>
    props.mobile ? "1vw solid black " : "0.4vw solid black"};
  box-shadow: 0 0 0.7vw black;
  border-radius: 0.4vw;
  margin: ${(props) =>
    props.mobile ? "6vh auto auto auto" : "6vh auto auto 1vw"};
`;

export const InfoBox = styled.div`
  background-color: ${(props) => props.color || Theme.WHITE};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  min-width: ${(props) => props.minWidth};
  min-height: ${(props) => props.minHeight};
  max-width: ${(props) => props.maxWidth};
  max-height: ${(props) => props.maxHeight};
  display: ${(props) => props.display || "flex"};
  margin: ${(props) => props.margin || "auto"};
  padding: ${(props) => props.padding || "0"};
  border-radius: 0.5vw;
  box-shadow: ${(props) => (props.shadow ? "0 0 1vw black" : "none")};
  border: ${(props) => props.border || ".4em solid"};
  border-color: ${(props) => props.borderColor || Theme.SECONDARY_COLOR};
  flex-direction: column;
`;

export const InfoCircle = styled.div`
  background-color: ${Theme.WHITE};
  margin: ${(props) => props.margin || "auto"};
  display: flex;
  box-shadow: 0 0 1vw black;
  width: ${(props) => props.width};
  height: ${(props) => props.width};
  border-radius: 100%;
`;

export const Divider = styled.div`
  height: ${(props) => props.height || "1vw"};
  display: ${(props) => props.display};
  width: ${(props) => props.width || "100%"};
  background-color: ${(props) => props.color || "black"};
  margin: ${(props) => props.margin || "auto"};
  border-radius: ${(props) => props.radius || "10px"};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns || "1fr 1fr 1fr"};
  grid-template-areas: ${(props) => props.gridTemplateAreas};
  padding: ${(props) => props.padding || "0"};
  zIndex: ${(props) => props.zIndex};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin || "auto"};
  border: ${(props) => props.border || "none"};
  border-bottom: ${(props) => props.borderBottom || "none"};
  box-shadow: ${(props) => props.boxShadow || "none"};
  background-color: ${(props) => props.backgroundColor || "none"};
  overflow: ${(props) => props.overflow || "visible"};
  overflow-x: ${(props) => props.overflowX || "visible"};
  overflow-y: ${(props) => props.overflowY || "visible"};
  animation: ${(props) => props.animation};
  animation-fill-mode: both;
  animation-delay: ${(props) => props.animationDelay};
trasform:rotateX
  trasform: rotate;
`;
