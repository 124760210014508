import React, { Component, Window } from "react";
import styled, { keyframes, css } from "styled-components";
import Circular from "../assets/logos/Secondary_Logo.png";
import axios from "axios";
import {
  Dropdown,
  NavButton,
  Text,
  Column,
  Clickable,
  Button,
  Row,
  ProfileButton,
  Section,
} from "./";
import Contact from "../pages/home/contact.js";
import Theme from "./Theme.js";
import {
  slideInDown,
  fadeInDown,
  fadeInUp,
  fadeIn,
  slideInRight,
  slideInLeft,
} from "react-animations";
import cookie from "js-cookie";
import windowSize from "react-window-size";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import Main_Logo from "../assets/logos/Main_Logo.png";
import Animated_Logo from "../assets/logos/Main_Logo.mp4";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class NavBar extends Component {
  state = {
    initialize: this.props.history.location.pathname === "/home",
    site: {},
    logo: 0,
  };
  fetchSite = () => {
    axios.defaults.headers.common["Authorization"] = "";
    axios
      .get(`${process.env.REACT_APP_API_URL}/site`, {
        params: {
          company: process.env.REACT_APP_COMPANY_NAME,
          name: process.env.REACT_APP_DOMAIN_NAME,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let background = "";
          res.data.site.backgrounds.map((back) => {
            if (back.page == "home") background = back.url;
          });
          this.setState({ site: res.data.site, loading: false, background });
        } else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchSite, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  getEmail(name) {
    const emails = this.state.site.emails;
    if (emails)
      for (var i = 0; i < emails.length; i++) {
        if (emails[i].name === name) return emails[i].value;
      }
    else return "";
  }

  getInfo(name) {
    const info = this.state.site.info;
    if (info)
      for (var i = 0; i < info.length; i++) {
        if (info[i].name === name) {
          return info[i].value;
        }
      }
    else return "";
  }

  getLogo(logo) {
    switch (logo) {
      case 0:
      //return Advanced;
      case 1:
      //return Basic;
      case 2:
      //return Header;
      case 3:
      //eturn Circle;
      default:
    }
  }

  componentDidMount() {
    this.fetchSite();
    //document.getElementById("vid").play();
  }
  render() {
    const { initialize, open, logo, loading, background } = this.state;
    const { onNavigate, onContactClose, contact } = this.props;

    const mobile = this.props.windowWidth < 1000 || this.props.mobile;
    const tiny = this.props.windowWidth < 500;
    const max = this.props.windowWidth > 1200;
    const shrink = this.props.windowWidth < 1000;
    if (initialize)
      setTimeout(() => this.setState({ initialize: false }), 1500);
    return (
      <div>
        <Contact
          mobile={mobile}
          open={contact || open}
          onClose={() => this.setState({ open: false }, onContactClose)}
        />
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: mobile ? "" : "0fr 1fr 1fr",
            height: mobile ? "20vh" : "15vh",
            width: "100%",
            margin: "0 0 auto auto",
            padding: 0,
            backgroundColor: "white",
            zIndex: 90,
            position: "fixed",
          }}
        >
          <div />
          {
            // <a href="/home" style={{ margin: mobile ? "auto " : 0 }}>
            //   <video
            //     id="vid"
            //     style={{
            //       padding: 0,
            //       height: "15vh",
            //     }}
            //     muted
            //   >
            //     <source
            //       src={Animated_Logo}
            //       style={{
            //         height: logo == 2 ? "auto" : "15vh",
            //         width: logo == 2 ? "30vw" : "auto",
            //       }}
            //     />
            //   </video>
            // </a>
          }
          <Clickable
            onClick={() => {
              this.props.history.push("/home");
              window.location.pathname == "/home" && onNavigate("top");
            }}
            animation={css`1.5s .5s ${keyframes`${fadeInDown}`}`}
            style={{
              margin: mobile ? "auto" : "0 1% 0 1%",
              padding: 0,
              height: logo == 2 ? "auto" : "15vh",
              width: mobile ? "100%" : "50%",
            }}
          >
            <img
              src={Main_Logo}
              style={{
                height: logo == 2 ? "auto" : "15vh",
                width: logo == 2 ? "30vw" : "auto",
              }}
            />
          </Clickable>
          <Column>
            <Text
              color={Theme.SECONDARY_COLOR}
              style={{ position: "fixed", top: "1%", right: "2%" }}
            >
              {this.getInfo("phone")}
            </Text>
            <Row
              noWrap={true}
              animation={css`1.5s .1s ${keyframes`${slideInRight}`}`}
            >
              <NavButton
                margin="auto 0 0 auto"
                onClick={() => onNavigate("about")}
                style={{
                  backgroundColor: Theme.MAIN_COLOR,
                  color: Theme.WHITE,
                  border: ".5px solid black",
                  borderRadius: 5,
                  padding: "1% 5% 1% 5%",
                }}
              >
                About
              </NavButton>
              <NavButton
                margin="auto 0 0 auto"
                onClick={() => onNavigate("process")}
                style={{
                  backgroundColor: Theme.MAIN_COLOR,
                  color: Theme.WHITE,
                  border: ".5px solid black",
                  borderRadius: 5,
                  padding: "1% 5% 1% 5%",
                }}
              >
                Our Process
              </NavButton>
              <NavButton
                margin="auto 1vw 0 auto"
                onClick={() => this.setState({ open: true })}
                style={{
                  backgroundColor: Theme.MAIN_COLOR,
                  color: Theme.WHITE,
                  border: ".5px solid black",
                  borderRadius: 5,
                  padding: "1% 5% 1% 5%",
                }}
              >
                Contact Us
              </NavButton>
            </Row>
          </Column>
        </Row>
        <Row animation={css`2s  ${keyframes`${fadeIn}`}`}>
          <img
            src={background}
            style={{
              position: "fixed",
              height: mobile ? "80vh" : "85vh",
              marginTop: mobile ? "20vh" : "15vh",
              backgroundColor: "white",
              minWidth: "100vw",
              padding: 0,
              zIndex: loading ? 200 : -1,
              right: 0,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "rgba(53,53,90,.0)",
              justifyContent: "flex-start",
              minHeight: "92vh",
              margin: 0,
              paddingTop: mobile ? "20vh" : "15vh",
            }}
          >
            {this.props.children}
          </div>
        </Row>
        <Row
          noWrap={!mobile}
          backgroundColor="white"
          width="100%"
          margin="0"
          animation={css`3s ${keyframes`${fadeIn}`}`}
        >
          <Row noWrap={!mobile}>
            <img
              src={Main_Logo}
              style={{
                width: "auto",
                height: "15vh",
                display: "flex",
                margin: mobile ? "auto" : "1% 10% 1% auto",
                left: "40vw",
                maxHeight: "300px",
              }}
            />
            <div
              style={{
                margin: mobile ? "auto" : "auto auto auto 0",
                display: "flex",
                flexDirection: "column",
                justifyItems: "space-between",
                width: mobile && "80vw",
                flexWrap: "wrap",
              }}
            >
              <Text
                color={Theme.BLACK}
                margin={mobile ? "auto" : "1% auto 5% 0"}
                size={mobile ? "3vw" : "1.2vw"}
              >
                {this.getInfo("phone")}
              </Text>
              <Text
                color={Theme.BLACK}
                margin={mobile ? "auto" : "1% auto 5% 0"}
                size={mobile ? "3vw" : "1.2vw"}
              >
                {this.getEmail("contact")}
              </Text>
              <Text
                color={Theme.BLACK}
                margin={mobile ? "auto" : "1% auto 5% 0"}
                size={mobile ? "3vw" : "1.2vw"}
              >
                {this.getEmail("Alternate")}
              </Text>
              <Column margin={mobile ? "auto" : "1% auto 5% 0"} width="auto">
                <Text color={Theme.BLACK} size={mobile ? "3vw" : "1.2vw"}>
                  {this.getInfo("address")}
                </Text>
                <Text
                  color={Theme.BLACK}
                  textAlign="left"
                  size={mobile ? "3vw" : "1.2vw"}
                >
                  {this.getInfo("city and state")}
                </Text>
              </Column>
            </div>
          </Row>
        </Row>
        <Column
          backgroundColor="white"
          width="100%"
          height="auto"
          animation={css`3s ${keyframes`${fadeIn}`}`}
          style={{ zIndex: 90 }}
        >
          <Text
            color={Theme.BLACK}
            margin="2% auto 0 auto"
            size={mobile ? "3vw" : "1vw"}
          >
            &copy;
            {` ${moment().format(
              "YYYY"
            )} Hampton Concrete LLC, All rights reserved`}
          </Text>
          <a
            href="https://nitely.dev"
            target="#"
            style={{ margin: "1% auto 1%", textDecoration: "none" }}
          >
            <Text
              color={Theme.BLACK}
              margin="1% auto 1% auto"
              size={mobile ? "5vw" : "1vw"}
            >
              Powered By Nitely
            </Text>
          </a>
          <Text id="seo-terms" color="white">
            Hampton Concrete | Concrete | Nashville Concrete | Local Concrete
            Service | Tennessee Concrete Service
          </Text>
        </Column>
      </div>
    );
  }
}

export default windowSize(NavBar);
