import React from "react";
import cookie from "js-cookie";
import axios from "axios";
import windowSize from "react-window-size";
import {
  NavBar,
  Section,
  Column,
  Text,
  InfoBox,
  Row,
  Clickable,
  Button,
  Slideshow,
  Divider,
  GridRow,
} from "../../globalComponents";
import { fadeInDown, rollIn, backInLeft, zoomIn } from "react-animations";
import Logo from "../../assets/logos/Main_Logo.png";
import Theme from "../../globalComponents/Theme.js";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import styled, { keyframes, css } from "styled-components";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

class Home extends React.Component {
  state = { contact: false, services: [], about: [], site: [] };
  fetchServices = () => {
    axios.defaults.headers.common["Authorization"] = "";
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/feed`, {
        params: {
          name: "Services",
          company: process.env.REACT_APP_COMPANY_NAME,
        },
      })
      .then((res) => {
        if (res.data.success)
          this.setState({ services: res.data.feed.entries });
        else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchFeed, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };
  fetchAbout = () => {
    axios.defaults.headers.common["Authorization"] = "";
    this.setState({ loading: true });
    axios
      .get(`${process.env.REACT_APP_API_URL}/feed`, {
        params: {
          name: "About",
          company: process.env.REACT_APP_COMPANY_NAME,
        },
      })
      .then((res) => {
        if (res.data.success)
          this.setState({ about: res.data.feed.entries }, this.fetchSite);
        else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchFeed, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };
  fetchSite = () => {
    axios.defaults.headers.common["Authorization"] = "";
    axios
      .get(`${process.env.REACT_APP_API_URL}/site`, {
        params: {
          company: process.env.REACT_APP_COMPANY_NAME,
          name: process.env.REACT_APP_DOMAIN_NAME,
        },
      })
      .then((res) => {
        if (res.data.success)
          this.setState({ site: res.data.site, loading: false });
        else {
          if (res.data.error === "Not Authorized")
            axios.defaults.headers.common["Authorization"] = "";
          this.setState({ error: "Unable to fetch services" }, () =>
            setTimeout(this.fetchSite, 500)
          );
        }
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  getInfo(name, multiline) {
    const info = this.state.site.info;
    if (info)
      for (var i = 0; i < info.length; i++) {
        if (info[i].name === name) {
          if (multiline) {
            return info[i].value.split(/\n/g);
          } else return info[i].value;
        }
      }
    else return "";
  }

  onNavigate = (name) => {
    const mobile = window.innerWidth < 600;
    const offset = window.innerHeight * (mobile ? 0.2 : 0.15);
    if (name == "process")
      window.scrollTo({
        top: this.processRef.offsetTop - offset,
        behavior: "smooth",
      });
    else if (name == "about")
      window.scrollTo({
        top: this.aboutRef.offsetTop - offset,
        behavior: "smooth",
      });
    else window.scroll({ top: 0, behavior: "smooth" });
  };

  componentDidMount() {
    this.fetchServices();
    this.fetchAbout();
    this.fetchSite();
  }
  render() {
    const { contact, services, loading, about } = this.state;
    const mobile = this.props.windowWidth < 900;
    return (
      <NavBar
        history={this.props.history}
        onNavigate={this.onNavigate}
        onContactClose={() => this.setState({ contact: false })}
        contact={contact}
      >
        <Section minHeight={mobile ? "80vh" : "85vh"} margin="0 0 auto 0">
          <Column
            margin="0"
            backgroundColor={Theme.MAIN_COLOR}
            animation={css`1s 1.4s ${keyframes`${fadeInDown}`}`}
            style={{ animationFillMode: "both" }}
          >
            <Text
              margin={mobile ? "auto" : "0 auto 0 auto"}
              color="white"
              size={mobile ? "200%" : "90px"}
              animation={css`1s 1.4s ${keyframes`${fadeInDown}`}`}
              weight="normal"
              fontFamily={Theme.HEADER_FONT}
            >
              {this.getInfo("title")}
            </Text>
            <Divider
              margin="0 auto 1vh auto "
              width={mobile ? "0vw" : "98vw"}
              style={{ display: mobile && "none" }}
              color={Theme.SECONDARY_COLOR}
              animation={css`1.5s 2s ${keyframes`${zoomIn}`}`}
            />
            <Text
              margin={mobile ? "2vh auto" : "0 auto 0 auto"}
              color="white"
              style={{ display: !this.getInfo("subtitle") && "none" }}
              size={mobile ? "100%" : "50px"}
              animation={css`1s 1.4s ${keyframes`${fadeInDown}`}`}
            >
              {this.getInfo("subtitle")}
            </Text>
          </Column>
          <Column
            style={{
              backgroundColor: "rgba(255,255,255,.8)",
              padding: "0",
              margin: "auto auto 0 auto",
            }}
            animation={css`1s 1.6s ${keyframes`${fadeInDown}`}`}
          >
            <Text
              color={Theme.MAIN_COLOR}
              size={mobile ? "6vw" : "70px"}
              fontFamily={Theme.HEADER_FONT}
              margin="1%"
            >
              {this.getInfo("contactUsMessage")}
            </Text>
            <Button
              onClick={() => this.setState({ contact: true })}
              fontSize={mobile ? "7vw" : "40px"}
              margin="0 auto 2vh auto"
              animation={css`1s 1.7s ${keyframes`${fadeInDown}`}`}
              padding="1% 2% 1% 2%"
              width={mobile ? "90%" : "50%"}
              style={{ borderRadius: 900 }}
            >
              Contact Us
            </Button>
          </Column>
        </Section>
        <Section id="about" ref={(ref) => (this.aboutRef = ref)}>
          <Column backgroundColor={Theme.MAIN_COLOR}>
            <Row width="98vw" style={{ flexDirection: "row" }}>
              {about.map((tile, index) => (
                <InfoBox
                  style={{
                    margin: "2% auto 2% auto",
                    width: mobile ? "90%" : "40%",
                  }}
                >
                  <Text
                    size={mobile ? "200%" : "300%"}
                    color={Theme.MAIN_COLOR}
                    margin="0 auto 5% 0"
                    fontFamily={Theme.HEADER_FONT}
                  >
                    {tile.title}
                  </Text>
                  <Text
                    size={mobile ? "100%" : "150%"}
                    margin="2%"
                    textAlign="left"
                  >
                    {tile.description}
                  </Text>
                </InfoBox>
              ))}
            </Row>
          </Column>
        </Section>

        <Section backgroundColor={Theme.MAIN_COLOR}>
          <Text
            size={mobile ? "220%" : "320%"}
            textAlign="left"
            color={Theme.SECONDARY_COLOR}
            margin="0 auto 2% 1%"
            fontFamily={Theme.HEADER_FONT}
          >
            {this.getInfo("aboutTitle")}
          </Text>
          <Text
            size={mobile ? "100%" : "150%"}
            margin="2% 2% 2em 2%"
            textAlign="left"
            color="white"
          >
            {this.getInfo("aboutDescription", true) &&
              this.getInfo("aboutDescription", true).map((str) => <p>{str}</p>)}
          </Text>
        </Section>
        <Section backgroundColor="white">
          {services.map((service, index) =>
            mobile ? (
              <Column
                height={mobile ? "auto" : "100%"}
                margin={mobile ? "auto auto .5em auto" : "auto"}
              >
                <img
                  src={service.image}
                  style={{
                    height: mobile ? "30vh" : "50%",
                    width: mobile ? "100vw" : "100%",
                    objectFit: "cover",
                  }}
                />
                <Column
                  backgroundColor="white"
                  width={mobile ? "100vw" : "100%"}
                  margin="0 auto auto 0"
                  height="50%"
                  style={{ padding: "2% 5% 2% 5%", boxSizing: "border-box" }}
                >
                  <Text
                    size={mobile ? "150%" : "250%"}
                    margin="1% auto 5% auto"
                    fontFamily={Theme.HEADER_FONT}
                  >
                    {service.serviceName}
                  </Text>
                  <Text weight="normal">{service.description}</Text>
                </Column>
              </Column>
            ) : index % 3 == 0 ? (
              <GridRow
                gridTemplateColumns={
                  services.length - index < 3
                    ? services.length - index < 2
                      ? "1fr"
                      : "1fr 1fr"
                    : "1fr 1fr 1fr"
                }
                style={{
                  gap: services.length - index < 3 ? "1em" : "0",
                  margin: "auto",
                  width:
                    services.length - index < 3
                      ? services.length - index < 2
                        ? "60%"
                        : "80%"
                      : "100%",
                }}
              >
                <Column
                  height={mobile ? "auto" : "100%"}
                  margin={mobile ? "auto auto .5em auto" : "auto"}
                >
                  <img
                    src={service.image}
                    style={{
                      height: mobile ? "30vh" : "50%",
                      width: mobile ? "100vw" : "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Column
                    backgroundColor="white"
                    width={mobile ? "100vw" : "100%"}
                    margin="0 auto auto 0"
                    height="50%"
                    style={{
                      padding: "2% 5% 2% 5%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Text
                      size={mobile ? "150%" : "250%"}
                      margin="1% auto 5% auto"
                      fontFamily={Theme.HEADER_FONT}
                    >
                      {service.serviceName}
                    </Text>
                    <Text weight="normal">{service.description}</Text>
                  </Column>
                </Column>
                <Column
                  height={mobile ? "auto" : "100%"}
                  margin={mobile ? "auto auto .5em auto" : "auto"}
                  display={services[index + 1] != null ? "flex" : "none"}
                >
                  <img
                    src={
                      services[index + 1] != null && services[index + 1].image
                    }
                    style={{
                      height: mobile ? "30vh" : "50%",
                      width: mobile ? "100vw" : "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Column
                    backgroundColor="white"
                    width={mobile ? "100vw" : "100%"}
                    margin="0 auto auto 0"
                    height="50%"
                    style={{
                      padding: "2% 5% 2% 5%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Text
                      size={mobile ? "150%" : "250%"}
                      margin="1% auto 5% auto"
                      fontFamily={Theme.HEADER_FONT}
                    >
                      {services[index + 1] != null &&
                        services[index + 1].serviceName}
                    </Text>
                    <Text weight="normal">
                      {services[index + 1] != null &&
                        services[index + 1].description}
                    </Text>
                  </Column>
                </Column>
                <Column
                  height={mobile ? "auto" : "100%"}
                  margin={mobile ? "auto auto .5em auto" : "auto"}
                  display={services[index + 2] != null ? "flex" : "none"}
                >
                  <img
                    src={services[index + 2] && services[index + 2].image}
                    style={{
                      height: mobile ? "30vh" : "50%",
                      width: mobile ? "100vw" : "100%",
                      objectFit: "cover",
                    }}
                  />
                  <Column
                    backgroundColor="white"
                    width={mobile ? "100vw" : "100%"}
                    margin="0 auto auto 0"
                    height="50%"
                    style={{
                      padding: "2% 5% 2% 5%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Text
                      size={mobile ? "150%" : "250%"}
                      margin="1% auto 5% auto"
                      fontFamily={Theme.HEADER_FONT}
                    >
                      {services[index + 2] && services[index + 2].serviceName}
                    </Text>
                    <Text weight="normal">
                      {services[index + 2] && services[index + 2].description}
                    </Text>
                  </Column>
                </Column>
              </GridRow>
            ) : null
          )}
        </Section>
        <Section
          id="process"
          backgroundColor="rgba(0,0,0,0.6)"
          ref={(ref) => (this.processRef = ref)}
        >
          <Text
            size={mobile ? "220%" : "320%"}
            color={Theme.SECONDARY_COLOR}
            margin="0 .5em 1em .5em"
            textAlign="left"
            fontFamily={Theme.HEADER_FONT}
          >
            {this.getInfo("processTitle")}
          </Text>
          <Text
            size={mobile ? "100%" : "150%"}
            margin="2%"
            textAlign="left"
            color="white"
          >
            {this.getInfo("processDescription", true) &&
              this.getInfo("processDescription", true).map((str) => (
                <p>{str}</p>
              ))}
          </Text>
        </Section>
      </NavBar>
    );
  }
}

export default windowSize(Home);
